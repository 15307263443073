<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Tahsilatlar</div>
        <button v-if="false" class="btn btn-primary" v-b-modal.modalcollection>Yeni</button>
      </div>
      <div class="card-body p-0">
        <div class="border-top border-bottom p-1">
          <div class="row">
            <div class="col-sm-2">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Başlangıç</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.start_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Bitiş</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.end_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Durum</small>
                <v-select
                  v-model="datasource.filter.status"
                  :options="[
                    { label: 'Beklemede', value: 'pending' },
                    { label: 'Tamamlandı', value: 'complated' },
                  ]"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mt-2">
                <button class="btn btn-primary" @click="getData()">
                  Uygula
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="firstlast-padding">
          <b-table
            ref="table_collection_list"
            responsive
            striped
            small
            hover
            select-mode="single"
            selectable
            :items="datasource.payments"
            :fields="datasource.table.fields"
            @row-selected="setTask"
            :tbody-tr-class="rowClass"
            class="mb-0 align-top"
            show-empty
          >
            <template #cell(customer_name)="data">
              {{ data.value }}
            </template>
            <template #cell(sf_id)="data">
              <router-link
                :to="{ name: 'service', query: { uniq: data.item.sf_uniq },}" target= '_blank'>
                <button class="btn btn-link pl-0">
                  #{{ data.value }} Servis No
                </button>
              </router-link>
            </template>
            <template #cell(amount)="data">
              {{ data.value }}
              {{ data.item.currency_name }}
            </template>
            <template #cell(category_id)="data">
              {{ data.item.category_name }}
            </template>
           <template #cell(planned_date)="data">
              {{ moment(data.value).format('DD.MM.YYYY') }}
            </template>
            <template #cell(status)="data">
              <span v-if="data.value == 'complated'" class="badge badge-success">Ödendi</span>
              <span v-if="data.value == 'pending'" class="badge badge-warning">Ödenmedi</span>
            </template>
            <template #empty>
              <div class="pt-3 pb-3 text-center">
                Listelenecek bir kayıt bulunmuyor.
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  <b-modal id="modalcollection" title="TAHSİLAT OLUŞTUR" hide-footer centered size="lg">
    <modal-collection-create></modal-collection-create>
  </b-modal>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import ModalCollectionCreate from "./ModalCollectionCreate.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
    ModalCollectionCreate
  },
  data() {
    return {
      datasource: {
        payments: [],
        cargo: null,
        filter: {
          start_date: null,
          end_date: null,
          type: "all",
          status:"pending"
        },
        table: {
          fields: [
            {
              key: "customer_name",
              label: "Müşteri",
              sortable: true,
              selected: true,
            },
            {
              key: "sf_id",
              label: "Servis No",
              sortable: true,
              selected: true,
            },
            {
              key: "amount",
              label: "Miktar",
              sortable: true,
              selected: true,
            },
            {
              key: "category_id",
              label: "Kategori",
              sortable: true,
              selected: true,
            },
            {
              key: "planned_date",
              label: "Ö. Gereken Tarih",
              sortable: true,
              selected: true,
            },
            { key: "status", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    getData() {
      let postData = this.datasource.filter;
      api.action("getCollections", { ...postData }).then((q) => {
        if (!q.success) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        this.datasource.payments = q.data.payments;
      });
    },
    setTask(item) {
      item = item[0];
      if (item) {
        this.sf.task = item;
        if (item.device_item != null) {
          this.sf.device = item.device_item;
        }
        if (item.delivery_item != null) {
          this.sf.delivery = item.delivery_item;
        }
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {
    this.datasource.filter.start_date = utils.moment().add(-30, "days").format("YYYY-MM-DD");
    this.datasource.filter.end_date = utils.moment().add(7,"days").format("YYYY-MM-DD");
    this.getData();
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>