<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <span class="font-medium text-uppercase">İlgili Servis</span>
      </div>
      <div class="col-sm-8 form-group">
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            placeholder="#Servis No"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button">
              Bul
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <span class="font-medium text-uppercase">İlgili Müşteri</span>
      </div>
      <div class="col-sm-8 form-group">
        <input type="text" class="form-control" readonly value="Kadir Malakcıoğlu">
      </div>
    </div>
 
  


  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import CardCustomerSearch from "@/views/common/CardCustomerSearch";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    vSelect,
    flatPickr,
    CardCustomerSearch,
  },
  data() {
    return {
      datasource: {
        packet_type: "device",
        packet_show_to: "list",
        customer_devices: [],
        cargo_items: [],
        table_customer_devices: {
          fields: [
            {
              key: "device_id",
              label: "Cihaz No",
              sortable: true,
              selected: true,
            },
            {
              key: "device_name",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            { key: "date_item", label: "Tarih", sortable: true },
            { key: "action_item", label: "", sortable: true },
          ],
        },
        table: {
          fields: [
            {
              key: "task_type",
              label: "Tür",
              sortable: true,
              selected: true,
            },
            {
              key: "task_name",
              label: "Görev",
              sortable: true,
              selected: true,
            },
            {
              key: "device_item",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            {
              key: "staff_item",
              label: "Personel",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status_item", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    ...mapActions(["saveCargo"]),
    ...mapMutations(["clearCargo"]),
    emitCustomerDevices($emit) {
      this.datasource.customer_devices = $emit;
    },
    saveCargoItem() {
      this.saveCargo().then((q) => {
        if (!q && !q.success) {
          return;
        }

        this.$router.replace({
          name: "cargotracking-detail",
          query: { uniq: q.data.cargo.c_uniq },
        });
      });
    },
    addCargoItem(item, type) {
      let item_name = "";
      if (type == "device") {
        item_name = item.device_name;
      }
      let new_item = {
        type: "type",
        device_id: item.device_id,
        item_name: item_name,
      };
      this.cargo.cargo_items.push(new_item);
    },
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    changeCity() {
      this.datasource.districts = [];
      this.getDistricts();
    },
    getDistricts() {
      let city_id = this.cargo.cargo.city_id;
      let items = utils.getDistricts(city_id, { viewtype: "vselect" });
      this.datasource.districts = items;
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {
    this.clearCargo();
    let au = utils.getActiveUser();
    this.cargo.cargo.target_office_id = au.office_id;
    this.cargo.cargo.city_id = "34";
    this.cargo.cargo.district_id = "0";
    this.cargo.cargo.c_id = 0;
    this.cargo.cargo.status = "pending";
    this.getDistricts();
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
    customer_devices() {
      let result = [];
      this.datasource.customer_devices.forEach((q) => {
        let item = {
          item: q,
          device_id: q.device_id,
          device_name: q.device_name,
          date_item: this.moment(q.created_props.created_at).format(
            "DD.MM.YYYY HH:mm"
          ),
        };
        result.push(item);
      });
      return result;
    },
    list_service_task() {
      let result = [];
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>